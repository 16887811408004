/* Component - Loader */

/* Mixin */
@mixin screenOverlay() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
}

/* Loader - Base */
.haae__Loader {
  /* Loader - Overlay */
  &.haae__Loader--full-screen {
    display: flex;
    flex: 1 1 auto;
  }

  @at-root #{&}__container {
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: auto;
    width: auto;
    transform: translate(-50%, -50%);
    padding: 10px 15px;
  }
}
