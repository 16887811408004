/* Core - Icons */

.haae_Icon__container {
  padding: 10px;
  border-radius: 50%;
  background: $lm-color-button-primary-background;
  color: $lm-color-icon-system-default;
}

.haae_Rounded__Icon__container {
  border-radius: 50%;
}

.haae__Icon__wrapper {
  position: relative;
  margin: 10px 0;

  .haae__Icon__wrapper__icon {
    position: absolute;
    transform-origin: left top;
  }

  &.haae__Icon__wrapper--lrg {
    height: 60px;
    width: 60px;

    .haae__Icon__wrapper__icon {
      transform: scale(1.5);
    }
  }
}

.haae__Image {
  &.haae__Image__App__Icon {
    max-width: 75px;
  }
}

@media (max-width: $media-breakpoint-tablet) {
  .haae__Icon__wrapper {
    .haae__Icon__wrapper__icon {
      position: initial;
    }

    &.haae__Icon__wrapper--lrg {
      height: initial;
      width: initial;

      .haae__Icon__wrapper__icon {
        transform: scale(1) !important;
      }
    }
  }
}
