/* Component - Cards */

.haae__Card {
  height: 100% !important;
  border-top: 2px solid $lm-color-modal-branded-background !important;

  &.haae__Card--size-lrg {
    width: 100%;
    max-width: 500px;
  }
}
