/* Component - iframes */

.haae__Card__iframe {
  .haae__Card__iframe__external {
    margin: -20px 0 20px;
    font-size: 0.875rem;
    text-align: center;

    & span,
    & svg {
      vertical-align: middle;
    }

    & svg {
      margin: 0 10px;
    }
  }
  .haae__Card__iframe-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    .haae__Card__iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      z-index: 10000;
    }
  }
}
