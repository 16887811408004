/* Component - Row */
.haae__Row__main {
  width: 1280px;
  padding: 0 16px;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
}

.haae__Row {
  display: flex;
  flex-flow: row wrap;

  &.haae__Row__main {
    width: auto;
    padding: 0;
  }

  &.haae__Row__gutters {
    margin: 0 -10px;

    & > [class^='haae__Column'] {
      padding: 0 10px;
    }
  }

  &.haae__Row__gutters--sml {
    margin: 0 -5px;

    & > [class^='haae__Column'] {
      padding: 0 5px;
    }
  }

  &.haae__Row__gutters + .haae__Row__gutters {
    margin-top: 20px;
  }

  &.haae__Row--column {
    flex-direction: column;
    height: 100%;
  }

  &.haae__Row--start {
    justify-content: flex-start;
  }

  &.haae__Row--center {
    justify-content: center;
  }

  &.haae__Row--end {
    justify-content: flex-end;
  }

  &.haae__Row--around {
    justify-content: space-around;
  }

  &.haae__Row--between {
    justify-content: space-between;

    .haae__Column {
      flex: 0 1 auto;
    }
  }

  &.haae__Row--stretch {
    align-items: stretch;
  }

  &.haae__Row--top {
    align-items: flex-start;
  }

  &.haae__Row--middle {
    align-items: center;
  }

  &.haae__Row--bottom {
    align-items: flex-end;
  }

  &.haae__Row--baseline {
    align-items: baseline;
  }

  &.haae__Row--no-wrap {
    flex-wrap: nowrap;
  }

  &.haae__Row--base-flex-auto {
    flex: 1 1 auto;
  }

  /* Columns */
  .haae__Column {
    max-width: 100%;
    min-height: 1px;

    &.haae__Column--base-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }

    &.haae__Column--base-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }

    &.haae__Column--base-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &.haae__Column--base-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

    &.haae__Column--base-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }

    &.haae__Column--base-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &.haae__Column--base-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    }

    &.haae__Column--base-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
    }

    &.haae__Column--base-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    &.haae__Column--base-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
    }

    &.haae__Column--base-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
    }

    &.haae__Column--base-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &.haae__Column--base-hide {
      display: none;
    }

    &.haae__Column--base-flex-none {
      flex: 0 0 auto;
    }

    &.haae__Column--base-flex-auto {
      flex: 1 1 auto;
    }

    &.haae__Column--base-flex-initial {
      flex: 0 1 auto;
    }
  }
}

img {
  width: 150px;
}

@media (max-width: $media-breakpoint-desktop-sml) {
  .haae__Row--desktop-sml {
    flex-direction: column !important;

    & > [class^='haae__Column'] {
      flex: 0 0 auto !important;
      max-width: 100% !important;
    }
  }
}

@media (max-width: $media-breakpoint-tablet) {
  .haae__Row {
    &.haae__Row--column {
      &.haae__Row--column--reset-tablet {
        flex-direction: row;
      }
    }

    &.haae__Row--column--tablet {
      flex-direction: column !important;
    }

    &.haae__Row--tablet {
      &.haae__Row__gutters {
        margin: 0;

        & > [class^='haae__Column'] {
          padding: 10px 0;
        }
      }

      & > .haae__Column {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

@media (max-width: $media-breakpoint-mobile) {
  .haae__Row {
    &.haae__Row--column--mobile {
      flex-direction: column !important;
    }
  }
}
