/* Component - LanguageSelect */
.haae_Form_language-select {
    min-width: 200px;
    padding: 3px;
    position: absolute;
    right: 0;
    top: 0;
    color: black;
    margin: 0px;
  }
