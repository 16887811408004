/* Component - Main */
.haae__Main {
  display: block;
  flex: 1 1 auto;
  word-break: break-word;
  word-wrap: break-word;

  .haae__Main__inner {
    padding: 20px 0;
  }
}

@media (max-width: $media-breakpoint-desktop-sml) {
  .haae__Main {
    [class^='haae__Column'].haae__Main__inner {
      flex: 1 1 auto !important;
      padding: 20px;
    }
  }
}
