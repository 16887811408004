/* Component - Footer */
.haae__Footer {
  .lm-Disclaimer {
    width: 100%;
  }
}

@media (max-width: $media-breakpoint-tablet) {
  .haae__Footer {
    .haae__Footer__diclaimer {
      text-align: center;
    }
  }
}
