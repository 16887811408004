/* Component - Lists */

%haae__List__base {
  margin: 10px 0 20px;
  padding: 0;
  list-style-type: none;

  li {
    padding-bottom: 1px;

    &:last-child {
      padding-bottom: 0;
    }

    &.haae__List__base--item-reset {
      font-weight: 400;

      &:before {
        content: '';
      }
    }
  }

  &.haae__List--indent {
    margin: 5px 0;

    & > li {
      margin-left: 20px;
    }
  }
}

.haae__List__base {
  @extend %haae__List__base;
}

.haae__List__serparator {
  @extend %haae__List__base;

  list-style-type: disc;

  & > li {
    margin-left: 20px;
    padding-left: 5px;
  }

  &.haae__List__serparator--number {
    list-style-type: decimal;
  }
}
