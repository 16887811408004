/* Components - Dividers */

.haae__Divider__sml {
  margin: 15px 0;
}

.haae__Divider__med {
  margin: 30px 0;
}

.haae__Divider__lrg {
  margin: 40px 0;
}
