.haae__State__display-none {
  display: none !important;
}

.haae__State_Flex_Grow {
  height: 100% !important;
}

.haae__State__margin_top_zero {
  margin-top: 0 !important;
}

.haae__State__text-align-center--reset-tablet {
  text-align: center;
}

.haae__State__vertical_align_middle {
  vertical-align: middle !important;
}

@media (max-width: $media-breakpoint-tablet) {
  .haae__State__text-align-center--reset-tablet {
    text-align: initial;
  }
}
