/* Components - Container */

.haae__Container__metric {
  min-width: 140px;
  padding: 15px;
  border: 1px solid $lm-color-border-light;
  border-radius: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
}
