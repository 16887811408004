/* Core - Base */
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,700,700i);

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0 auto;
  font: 16px Roboto, Arial, sans-serif;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.6em;
}

body,
button,
input,
select,
textarea {
  direction: ltr;
  text-align: left;
}

nav {
  margin: 10px 0;
}

a,
a:visited {
  text-decoration: none;
  cursor: pointer;

  &.mpw-a--state-selected {
    cursor: default;
  }

  &:active,
  &:focus,
  &:hover,
  &.mpw-a--state-selected {
    text-decoration: underline;
  }
}

p {
  margin: 10px 0;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

.haae__Stage {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100vh;
  width: 100%;
  min-width: $media-breakpoint-mobile-min;
  overflow: auto;

  > * {
    flex: 0 0 auto;
  }

  .haae__Stage--flex-auto {
    display: flex;
    flex-grow: 1;
  }

  @at-root #{&}__inner {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    @at-root #{&}--main {
      max-width: $media-breakpoint-desktop-max;
      padding: 0 !important;
      height: 100%;
    }
  }
}
